'use client'

import AuthService from '@/features/auth/auth-service'
import useCurrentMarket from '@/features/caching/hooks/useCurrentMarket'
import { TranslationBundles } from '@/features/sitecore/configs/translation-bundles'
import { useSitecoreState } from '@/features/sitecore/store/useSitecoreState'
import { staticGA4ParamsFromUser } from '@/utils/analytics/googleAnalyticsHelper'
import { useEnvironmentState } from '@/utils/environment/store/useEnvironmentState'
import { useModalState } from '@/utils/hooks/useModalState'
import { signOut } from 'next-auth/react'
import React from 'react'
import { useInterval } from 'usehooks-ts'
import useLoggedUser from '../hooks/useLoggedUser'

export const ExpiredSessionHandler: React.FC = () => {
	const market = useCurrentMarket()
	const sitecore = useSitecoreState((state) => state)
	const user = useLoggedUser()

	if (!market) return <></>

	const sendSessionExpiredGA4Event = () => {
		window.dataLayer = window.dataLayer || []
		window.dataLayer.push({
			event: 'sessionendrelogin',
			eventName: 'session_end_btn',
			...staticGA4ParamsFromUser(user, market),
			label_clicked: 'Session Ended',
		})
	}

	// eslint-disable-next-line react-hooks/rules-of-hooks
	const interval = useEnvironmentState().getNumber('CHECK_WEBSPHERE_SESSION_INTERVAL', 60) * 1000 // default: 1 minute
	// eslint-disable-next-line react-hooks/rules-of-hooks
	useInterval(() => checkSession(), interval)

	const checkSession = async () => {
		const sessionAlive = await AuthService.isSessionAliveOnWebsphere(market)
		if (sessionAlive) return

		try {
			await AuthService.websphereLogout()
			await signOut({ redirect: false })
		} catch (error) {
			console.error('Error while signing out:', error)
		}

		useModalState.getState().openInfoModal(
			{
				variant: 'ok',
				confirmBehavior: () => {
					sendSessionExpiredGA4Event()
					window.location.href = `/${market.locale}/welcome`
				},
				children: sitecore.getLabel(TranslationBundles.HEADER, 'MSC_PAGES-TIMER_COUNTDOWN_EXPIRED', 'Session expired'),
			},
			{
				preventClose: true,
			}
		)
	}

	return <></>
}
