'use client'

import useLoggedUser from '@/features/auth/hooks/useLoggedUser'
import useCurrentMarket from '@/features/caching/hooks/useCurrentMarket'
import { usePathname } from 'next/navigation'
import { useEffect } from 'react'

export const VirtualPageViewGA4 = () => {
	const user = useLoggedUser()
	const market = useCurrentMarket()
	const pathName = usePathname()
	const pageTitle = pathName.split('/').pop()

	useEffect(() => {
		if (typeof window !== undefined) {
			window.dataLayer = window.dataLayer || []
			window.dataLayer.push({
				event: 'virtualPageview',
				vPageUrl: `${window.location.origin}${pathName}`,
				vPageTitle: pageTitle,
				market: market?.code || '',
				pageSection: user ? 'Private' : 'Public',
				pageContent: '',
				language: market?.language.value || '',
				agency_id: user?.agency || '',
				user_id: user?.agency || '',
				market_code: market?.code || '',
			})
		}
	}, [pathName])

	return null
}
