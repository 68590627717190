'use client'

import useCurrentMarket from '@/features/caching/hooks/useCurrentMarket'
import { useEffect, useRef } from 'react'
import { useEnvironmentState } from '../environment/store/useEnvironmentState'

export const ABTastyClientWrapper = () => {
	const market = useCurrentMarket()
	const environmentState = useEnvironmentState()
	const scriptAdded = useRef(false)

	useEffect(() => {
		if (scriptAdded.current) return

		const environment = environmentState.getCurrentEnvironment()
		const abtastyScriptSrc = abtastyConfig[environment]?.[market.code]

		if (!abtastyScriptSrc) return
		const script = document.createElement('script')
		script.src = abtastyScriptSrc
		script.async = true
		document.body.appendChild(script)
		scriptAdded.current = true

		return () => {
			document.body.removeChild(script)
			scriptAdded.current = false
		}
	}, [market])

	return null
}

type AbtastyConfig = { [key: string]: { [key: string]: string } }
const abtastyConfig: AbtastyConfig = {
	INT: {
		BRA: '//try.abtasty.com/fbfc33805b34efcf2a7e9557821e91ba.js',
		ARG: '//try.abtasty.com/7cb4463912788dabef9c2d3bae096dc6.js',
		USA: '//try.abtasty.com/13f5df8f606f4054cb865d9ab00699c2.js',
		FRA: '//try.abtasty.com/2011403846375982015ef24f24dbadc2.js',
		SPA: '//try.abtasty.com/8c823c1539048b9df75c6d27ef9fdf4a.js',
		GBR: '//try.abtasty.com/84805d756fd9400dd917c9184d637240.js',
		GER: '//try.abtasty.com/f93f48311e76a307dbc9ce939ac67d63.js',
		ITA: '//try.abtasty.com/70995a30643c27708f6e2c3b27062844.js',
	},
	PROD: {
		BRA: '//try.abtasty.com/fbfc33805b34efcf2a7e9557821e91ba.js',
		ARG: '//try.abtasty.com/7cb4463912788dabef9c2d3bae096dc6.js',
		USA: '//try.abtasty.com/0c66245b927fab18a3f3dddb9dbe041a.js',
		FRA: '//try.abtasty.com/2011403846375982015ef24f24dbadc2.js',
		SPA: '//try.abtasty.com/8c823c1539048b9df75c6d27ef9fdf4a.js',
		GBR: '//try.abtasty.com/84805d756fd9400dd917c9184d637240.js',
		GER: '//try.abtasty.com/c0643ac4a82b2e640ad96d38d5acfd48.js',
		ITA: '//try.abtasty.com/ec707be2b719c081b28b2850dba77fb9.js',
	},
}
