'use client'

import { useEffect } from 'react'
import useLoggedUser from '@/features/auth/hooks/useLoggedUser'

const AppcuesClientWrapper: any = () => {
	const user: any = useLoggedUser()
	useEffect(() => {
		if (typeof window === 'undefined' || !user) return
		// Load Appcues
		const script = document.createElement('script')
		script.src = `//fast.appcues.com/200609.js`
		script.async = true
		document.body.appendChild(script)

		// Identify user
		script.onload = () => {
			if (window.Appcues) {
				window.Appcues.identify(`${user.market}_${user.id}_${user.agency}`, {
					agency: user.agency,
					user: `${user.market}_${user.id}_${user.agency}`,
					consortium: user.consortiumCode,
					office: user.office,
					market: user.market,
					language: user.language,
				})
			}
		}

		return () => {
			document.body.removeChild(script)
		}
	}, [user])
}

export default AppcuesClientWrapper
